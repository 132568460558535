<template>
  <div class="voucher_offers-list">
    <div class="d-flex align-center flex-sm-nowrap flex-wrap">
      <div class="text-h5 font-weight-bold">Gift voucher offers</div>
      <v-spacer></v-spacer>
      <v-card color="accent" flat tile class="ms-sm-3 d-flex align-center flex-wrap width100" max-width="520">
        <v-text-field
          class="field46 white mt-sm-0 mt-2"
          v-model="search"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          :append-icon="search ? 'mdi-close' : ''"
          @click:append="clearSearch"
          hide-details
          outlined
          dense
          required
          color="primary"
          height="46"
          @change="(page = 1), getData()"
        ></v-text-field>
        <v-btn @click="modal = true" depressed width="136" large class="primary mt-sm-0 mt-2 ms-4">Add new</v-btn>
      </v-card>
    </div>
    <div class="mt-4">
      <v-card tile flat color="transparent" v-if="!data.result" class="d-flex justify-center align-center" :height="`calc(100vh - 240px)`">
        <div>
          <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
          <div class="text-center text--text mt-4">Sorry, but nothing found</div>
        </div>
      </v-card>
      <div v-else>
        <v-data-table
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="data.result"
          item-key="id"
          :items-per-page="-1"
          :height="`calc(100vh - 240px)`"
          class="d-sm-block d-none accent"
        >
          <template v-slot:header.status>
            Status
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="btn-sort" :class="status != 'all' ? 'primary white--text' : ''">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :class="status == item.id ? 'primary--text font-weight-bold' : ''"
                  class="text-body-2"
                  link
                  v-for="item in itemsStatus"
                  :key="item.id"
                  @click="(status = item.id), getData()"
                  style="min-height: 36px"
                >
                  {{ item.title }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-if="!data.pages" v-slot:no-data>
            <v-card tile flat color="transparent" class="d-flex justify-center align-center">
              <div>
                <v-img min-height="150" height="220" contain src="@/assets/img/bad.svg"></v-img>
                <div class="text-center text--text mt-4">Sorry, but nothing found</div>
              </div>
            </v-card>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" class="link" @click="openItem(item.id)" style="height: 48px">
                <td class="text-capitalize">{{ item.title_en }}</td>
                <td>SAR {{ item.price / 100 }}</td>
                <td>
                  <span v-if="item.vat_option.type == 'fixed'">SAR</span>
                  {{ item.vat_option.value / 100 }}<span v-if="item.vat_option.type == 'percent'">%</span>,
                  {{ item.vat_included ? 'incl.' : 'not incl.' }}
                </td>
                <td>
                  <v-icon color="primary">{{ item.status == 'listed' ? 'mdi-check' : 'mdi-close' }}</v-icon>
                  {{ item.status == 'listed' ? 'Listed' : 'Unlisted' }}
                </td>
              </tr>
            </tbody>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-2 pb-2 px-3 d-flex align-center">
              <div class="text--text">Total {{ data.total }} {{ data.total > 1 ? 'offers' : 'offer' }}</div>
              <v-spacer></v-spacer>
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
          </template>
        </v-data-table>
        <div class="d-block d-sm-none">
          <div v-for="item in data.result" :key="item.id" @click="openItem(item.id)" class="px-4 pt-2">
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Title</div>
              <v-spacer></v-spacer>
              <div class="font-weight-medium f18 text-end">{{ item.title_en }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Price</div>
              <v-spacer></v-spacer>
              <div class="font-weight-medium f18 text-end">SAR {{ item.price / 100 }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">VAT</div>
              <v-spacer></v-spacer>
              <div class="font-weight-medium f18 text-end">
                <span v-if="item.vat_option.type == 'fixed'">SAR</span>
                {{ item.vat_option.value / 100 }}<span v-if="item.vat_option.type == 'percent'">%</span>,
                {{ item.vat_included ? 'incl.' : 'not incl.' }}
              </div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Status</div>
              <v-spacer></v-spacer>
              <div class="font-weight-medium f18 text-end">
                <v-icon color="primary">{{ item.status == 'listed' ? 'mdi-check' : 'mdi-close' }}</v-icon>
                {{ item.status == 'listed' ? 'Listed' : 'Unlisted' }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="mt-2 pb-2 px-3 d-flex align-center justify-center flex-wrap">
            <div class="text--text">Total {{ data.total }} {{ data.total > 1 ? 'offers' : 'offer' }}</div>
            <v-spacer></v-spacer>
            <div class="ms-2">
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="modal" max-width="700">
      <v-card flat outlined>
        <div class="font-weight-bold mb-3 mt-4 text-center">Add voucher offer</div>
        <v-card outlined tile color="px-10">
          <v-card
            @click="addPicture"
            flat
            class="mx-auto mt-4 d-flex align-center justify-center gray darken-5"
            max-width="200"
            width="100%"
            height="200"
          >
            <div class="white--text text-center" v-if="!image">
              <v-img max-width="44" class="mx-auto" src="@/assets/icon/upload.svg"></v-img>
              <div class="font-weight-bold">Upload picture</div>
              <div>600x600</div>
            </div>
            <v-img v-else :src="image"></v-img>
          </v-card>
          <v-card tile flat min-height="16" class="error--text text-caption text-center">{{ pictureErrors.join() }}</v-card>
          <div class="text--text mb-2">Provide information in English and Arabic</div>
          <div class="d-flex flex-md-row flex-column multi-direct">
            <v-textarea
              class="field46 width100 me-md-3"
              v-model="modalData.title_en"
              placeholder="Title"
              :error-messages="titleEnErrors"
              auto-grow
              rows="1"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
            <v-textarea
              class="field46 width100 mt-md-0 mt-n2 ms-md-3"
              v-model="modalData.title_ar"
              placeholder="عنوان"
              :error-messages="titleArErrors"
              auto-grow
              rows="1"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
          </div>
          <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
            <v-textarea
              class="width100 me-md-3"
              v-model="modalData.description_en"
              placeholder="Description"
              :error-messages="desEnErrors"
              auto-grow
              rows="4"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
            <v-textarea
              class="width100 mt-md-0 mt-n2 ms-md-3"
              v-model="modalData.description_ar"
              placeholder="وصف كامل"
              :error-messages="desArErrors"
              auto-grow
              rows="4"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
          </div>
          <div class="text--text mb-4 mt-n2">Price</div>
          <v-text-field
            class="field46 mt-n2 width100"
            :style="$vuetify.breakpoint.width >= 960 ? 'max-width: 287.5px' : ''"
            v-model="modalData.priceR"
            placeholder="0"
            suffix="SAR"
            :error-messages="priceErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <div class="d-flex flex-md-row flex-column">
            <div class="me-md-3 width100">
              <div class="text--text mb-4 mt-n2">VAT type</div>
              <v-select
                class="field46 mt-n2"
                v-model="modalData.vat_option"
                :items="typeList"
                placeholder="Select type"
                :error-messages="typeErrors"
                item-text="title"
                item-value="id"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-select>
            </div>
            <v-checkbox v-model="modalData.vat_included" color="success" class="mt-md-8 mt-0 mb-md-0 mb-6 ms-md-3 width100" hide-details>
              <template v-slot:label>
                <span class="black--text">VAT included in price</span>
              </template>
            </v-checkbox>
          </div>
        </v-card>
        <div class="text-end py-5 px-10">
          <v-btn @click="modal = false" class="rounded" depressed width="106" height="30" outlined>Cancel</v-btn>
          <v-btn @click="create" class="ms-3 rounded" depressed width="106" height="30" color="primary">Add</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      modalData: {
        vat_included: false,
      },
      file: '',
      image: '',
      search: this.$route.query.src || '',
      status: this.$route.query.status || 'all',
      page: Number(this.$route.query.page) || 1,
      headers: [
        { text: 'Title', value: 'title_en', sortable: false },
        { text: 'Price', value: 'price', sortable: false },
        { text: 'VAT', value: 'vat_option', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
      ],
      itemsStatus: [
        { id: 'all', title: 'All' },
        { id: 'listed', title: 'Listed' },
        { id: 'unlisted', title: 'Unlisted' },
      ],
      statusError: null,
      error: [],
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getSettingsVat', '?all=true');
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'create') {
        this.$notify({
          title: 'Done!',
          message: 'Voucher offer created successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    addPicture() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    openItem(id) {
      this.$router.push(`/gift-voucher-offers/${id}`);
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      this.statusError = null;
      await this.$store
        .dispatch('getVoucherOffersList', {
          search: this.search,
          status: this.status,
          page: this.page,
        })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
    async create() {
      this.error = [];
      const formData = new FormData();
      for (var key in this.modalData) {
        formData.append(key, this.modalData[key]);
      }
      formData.append('price', this.modalData.priceR * 100);
      this.file ? formData.append('picture', this.file) : '';
      await this.$store
        .dispatch('createVoucherOfferItem', formData)
        .then((res) => {
          this.data.result.unshift(res.data);
          this.modal = false;
          this.notifi('create');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  watch: {
    modal() {
      this.error = [];
      this.modalData = { vat_included: false };
    },
  },
  computed: {
    data() {
      return this.$store.getters.voucherOffersList;
    },
    typeList() {
      return this.$store.getters.settingsVat;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    desEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    desArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    pictureErrors() {
      const errors = [];
      this.error.find((item) => item == 'picture__required') && errors.push('Please select picture');
      this.error.find((item) => item == 'picture__invalid') && errors.push('Selected picture is not valid');
      return errors;
    },
    priceErrors() {
      const errors = [];
      this.error.find((item) => item == 'price__required') && errors.push('Please enter price');
      this.error.find((item) => item == 'price__invalid') && errors.push('Provided price is not valid');
      return errors;
    },
    typeErrors() {
      const errors = [];
      this.error.find((item) => item == 'vat_option__required') && errors.push('Please select type');
      this.error.find((item) => item == 'vat_option__invalid') && errors.push('Selected type is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setVoucherOffersList');
  },
};
</script>

<style lang="scss">
.voucher_offers-list {
  .btn-sort {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    .v-icon {
      font-size: 16px;
    }
  }
  table tbody tr:hover td:first-of-type {
    color: var(--v-primary-base) !important;
  }
}
</style>
